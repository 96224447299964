{
  "conversations": {
    "conversationProviders": "Conversation Providers",
    "viewTheListOfConversation": "View the list of conversation custom providers added to the account here.",
    "logo": "Logo",
    "name": "Name",
    "alias": "Alias",
    "type": "Type"
  }
}
