import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
const HomePage = () => import("@/pages/Home.vue");
const ConversationsProviderSettingsPage = () =>
  import("@/pages/ConversationsProviderSettingsPage.vue");
export const routes: Readonly<RouteRecordRaw[]> = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/v2/location/:locationId/settings/conversation_providers",
    component: ConversationsProviderSettingsPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export { router };
